import axios from 'axios'
import Router from '@/router/index'
import { Toast } from 'vant';
import store from '../store'
const service = axios.create({
    // request interceptor
    //         baseURL: process.env.NODE_ENV === 'development' ? '' : process.env.VUE_APP_BASE_API, // url = base url + request url
    //         withCredentials: true, // send cookies when cross-domain requests
    headers: {
        "X-Request-Origin": 'XCX'
    },
    timeout: 5000 // request timeout
})
// request interceptor

service.interceptors.request.use(
    config => {
        let date = Date.parse(new Date()) || 0;
        let userInfo
        if(!store.state.userInfo){
            userInfo = localStorage.getItem('userInfo');
            userInfo = JSON.parse(userInfo)
            store.commit('setUserInfo',userInfo)
        }else if(store.state.userInfo){
            userInfo = store.state.userInfo
        }
        if (userInfo) {
            config.headers["AuthToken"] = userInfo.userToken
        }
        let origin = store.state.origin;
        if(!origin){
            origin = Router.currentRoute.meta.origin || 'YQH5';
            store.commit('setOrigin',origin)
        }
        config.headers["X-USER-AGENT"] = `${origin}|1.5.0|${origin}|${origin}||${date}||false||`
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code !== 200 && res.code !== 110) {
            Toast(res.msg);
            return res
        }
        return res
    },
    error => {
        console.log('err' + error) // for debug
        Toast('err' + error);
        return error
    }
)
export default service
